import * as React from 'react';
import {Column, Row} from '../../../componentsLib/Layout';
import {useCommentSalesDoc, useGetSalesDocComments, useUsersCache} from '../../../hooks/api';
import {BusySpinner, Button, UserAvatar} from '../../../componentsLib/Basic';
import {BodyText} from '../../../componentsLib/Text';
import {formatDateTimeRelative} from '../../../utils';
import {registerGlobalStyle} from '../../../theme';
import {Mention, MentionsInput} from 'react-mentions';
import {useCallback, useMemo, useState} from 'react';
import {Collapse} from '@mui/material';

registerGlobalStyle('.comments-tab', (theme) => ({
  '.comment-title .text-body': {
    color: theme.colors.text.medium,
    lineHeight: 1.5,
    '&:first-child': {fontWeight: theme.typography.bold},
    '&:last-child': {fontWeight: theme.typography.light},
  },
  'textarea': {outline: 'none'},
  '.comment-entry .mentions:focus-within textarea': {borderColor: theme.colors.border.highlight},
}));

const displayTransform = (id, display) => ` @${display} `;

export function SalesDocCommentsTab({salesDoc}) {
  const {data: {comments}, isLoading, refetch} = useGetSalesDocComments(salesDoc._id);
  const {comment: saveCommentApi, isInProgress: isSavingComment} = useCommentSalesDoc();
  const {data: {users: _users}} = useUsersCache();
  const users = useMemo(() => _users
      .filter(({status}) => status === 'ACTIVE')
    .map((user) => ({email: user.username, id: user._id.toString(), display: user.fullName})),
    [_users]);
  const [newComment, setNewComment] = useState(null);

  const handleFocusComment = useCallback(() => {
    setNewComment((prev) => prev ?? '');
  }, []);

  const handleSaveComment = useCallback(async () => {
    await saveCommentApi({id: salesDoc._id, comment: newComment});
    setNewComment(null);
    await refetch();
  }, [newComment, refetch, salesDoc._id, saveCommentApi]);

  const handleClearComment = useCallback(() => {
    setNewComment(null);
  }, []);

  const handleChangeComment = useCallback((ev) => {
    setNewComment(ev.target.value);
  }, []);

  return (
    <Column className={'comments-tab'} paper fillWidth pad gap>
      {(isLoading && !comments) &&
        <BusySpinner message={'Loading comments'} left />
      }
      {(!isLoading || comments) &&
        <>
          <Column className={'comment-entry'} alignStretch gap>
            <MentionsInput
              value={newComment ?? ''}
              onChange={handleChangeComment}
              readOnly={false}
              className='mentions'
              placeholder={'Add a comment & mention people using \'@\''}
              onFocus={handleFocusComment}
            >
              <Mention
                trigger='@'
                data={users}
                className={'mentions__mention'}
                displayTransform={displayTransform}
              />
            </MentionsInput>
            <Collapse in={newComment != null}>
              <Row gap>
                <Button navPositive disabled={!newComment} onClick={handleSaveComment} loading={isSavingComment}>Save</Button>
                <Button navNegative disabled={newComment == null} onClick={handleClearComment}>Cancel</Button>
              </Row>
            </Collapse>
          </Column>
          {comments.comments.map((comment) => (
            <>
              <Row gap>
                <UserAvatar name={comment.madeBy}/>
                <Column>
                  <Row className={'comment-title'} gap>
                    <BodyText text={comment.madeBy}/>
                    <BodyText text={formatDateTimeRelative(comment.madeAt)}/>
                  </Row>
                  <MentionsInput
                    value={comment.content}
                    readOnly={true}
                    className='mentions mentions__readonly'
                  >
                    <Mention
                      trigger='@'
                      data={users}
                      className={'mentions__mention'}
                      appendSpaceOnAdd={false}
                    />
                  </MentionsInput>
                </Column>
              </Row>
            </>
          ))}
        </>
      }
    </Column>
  );
}
