import {createTheme} from '@mui/material';
import {colors} from '.';
import theme from '../ui/theme';

export const hoopsTheme = createTheme({
  colors,
  disabled: {opacity: .75},
  font: 'Rubik, sans-serif',
  spacing: (arg) => typeof arg === 'string' ? arg : `${arg * 0.5}rem`,
  spacingPx: (arg) => arg * window.getComputedStyle(document.documentElement).fontSize * 0.5,
  fontSize: (arg) => `${arg / 16}rem`,
  media: {
    phone: () => theme.breakpoints.down('sm'),
    tablet: () => theme.breakpoints.between('sm', 'md'),
    phoneOrTablet: () => theme.breakpoints.down('md'),
    desktop: () => theme.breakpoints.up('md'),
  },
  shadows: {
    paper: '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
    popoverElevation: '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
    hoverElevation: '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
    dragElevation: '4px 4px 10px 1px #0000002F',
    paginationElevation: '0 20px 30px 0 #0000002F'
  },
  transitions: {
    in: { // Note: in should be applied to the hover state for hover transitions
      background: 'background-color .2s linear',
      border: 'border-color .2s linear',
      color: 'color .2s linear',
      duration: '.2s',
      filter: 'filter .2s linear',
      function: 'linear',
      opacity: 'opacity .2s linear',
      outline: 'outline-color .2s linear',
      shadow: 'box-shadow .2s linear',
      width: 'width .2s linear',
      all: 'background-color .2s linear, border-color .2s linear, color .2s linear, filter .2s linear, opacity .2s linear, outline .2s linear, box-shadow .2s linear, width .2s linear',
    },
    out: { // Note: out should be applied to the non-hover state for hover transitions
      background: 'background-color .1s linear',
      border: 'border-color .1s linear',
      color: 'color .1s linear',
      duration: '.1s',
      filter: 'filter .1s linear',
      function: 'linear',
      opacity: 'opacity .1s linear',
      outline: 'outline-color .1s linear',
      shadow: 'box-shadow .1s linear',
      width: 'width .1s linear',
      all: 'background-color .1s linear, border-color .1s linear, color .1s linear, filter .1s linear, opacity .1s linear, outline .1s linear, box-shadow .1s linear, width .1s linear',
    },
  },
  typography: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '0.875rem',
    light: 300,
    normal: 400,
    bold: 500,
    dark: 500,
    darker: 600,
    darkest: 800,
  },
  zIndex: {
    hovers: 100,
    alerts: 200,
    toolbox: 1100,
    appBar: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    imageZoom: 2000
  },
});
