import React from 'react';
import {BrowserRouter, Route, withRouter} from 'react-router-dom';
import SingleGuestQuoteView from '../../componentsOld/quotes/SingleQuote/SingleGuestQuoteView';
import ProofPreview from '../../componentsOld/jobs/Guest/ProofPreview';
import GuestViewPurchaseOrder from '../../componentsOld/purchase-orders/SinglePurchaseOrder/GuestViewPurchaseOrder/GuestViewPurchaseOrder';
import {SalesDocGuestPage} from '../../pages/SalesDoc/Pages/SalesDocGuestPage';

export const GUEST_BASENAME = '/public';

let GuestRoutes = () => (
      <>
        <BrowserRouter basename={GUEST_BASENAME}>
          <>
            <Route path='/quotes/:quoteId/view' exact component={SingleGuestQuoteView}/>
            <Route path='/quotes/:quoteId/viewGroup' exact render={(props) => <SingleGuestQuoteView {...props} renderSiblings={true} />} />
            <Route path='/jobs/:jobId/proofs' exact component={ProofPreview}/>
            <Route path='/purchase-orders/:orderId/view-purchase-order' exact component={GuestViewPurchaseOrder}/>
            <Route path='/sales/:salesDocId/view' exact component={SalesDocGuestPage}/>
            <Route path='/sales/:salesDocId/pdf' exact render={() => <SalesDocGuestPage hideActionButtons={true} printPdf={true} />}/>
          </>
        </BrowserRouter>
      </>
    );

GuestRoutes = withRouter(GuestRoutes);
export default GuestRoutes;
