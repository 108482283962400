import React, {useCallback, useEffect, useState} from 'react';
import copy from 'copy-to-clipboard';
import ValidatedIcon from '@mui/icons-material/CheckCircleOutline';
import ValidationFailedIcon from '@mui/icons-material/CancelOutlined';
import ValidationPendingIcon from '@mui/icons-material/WatchLaterOutlined';
import CopyIcon from '@mui/icons-material/FileCopy';
import SyncIcon from '@mui/icons-material/Sync';
import {Delete as DeleteIcon,} from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Column, Grid, Row, Table, TableCell, TableRow} from '../../../componentsLib/Layout';
import {useFetchSalesDocHostingStatus} from '../../../hooks/api';
import {Button, Decorator, ImageThumbnail, Link, Switch, TextInput} from '../../../componentsLib/Basic';
import {BodyText, HeadingText} from '../../../componentsLib/Text';
import {registerGlobalStyle} from '../../../theme';
import {useMountEffect, useS3UploadCache} from '../../../hooks';
import {MaxFileSize, valueFromEvent} from '../../../utils';
import {ImageAndFileUploadManager} from '../../../componentsLib/DragDrop';
import {Chip} from '../../../componentsLib/Chips';
import Youtube from 'react-youtube';

registerGlobalStyle('.sales-store-hosting-tab', (theme) => ({
  '.hosting-details': {
    '.box-grid': {
      columnGap: theme.spacing(3),
      rowGap: theme.spacing(3),
    },
    '.hosting-details-property': {
      rowGap: theme.spacing(1),
      width: '100%',
      '.text-body': {lineHeight: 1.2},
      '.text-input.container': {
        display: 'flex',
        alignItems: 'baseline',
        width: '100%',
      },
      '.image-upload-manager': {
        width: '100%',
        '.image-selector': {padding: 0},
        '.file-select': {
          height: 'unset',
          border: 'none',
          display: 'block',
          zIndex: 2,
        },
      },
      '.favicon-uploader, .favicon-file': {
        height: 35,
        '.text-body': {color: theme.colors.text.main}
      },
      '.favicon-uploader': {
        position: 'relative',
        '.text-body': {
          position: 'absolute',
          left: 40,
          fontSize: theme.fontSize(16)
        },
        '&:hover': {'.text-body': {color: theme.colors.text.highlight}}
      },
      '.favicon-file': {'.MuiSvgIcon-root': {color: theme.colors.palette.greyLighter,}}

    },
  },
  '.dns-records': {
    '.table': {
      'th, td': {padding: theme.spacing(0, 1)},
      'th > *': {whiteSpace: 'nowrap'},
      'td > *': {verticalAlign: 'middle'},
      '.record-name, .record-value': {'.button': {marginLeft: theme.spacing(.5)}}
    }
  },
  '.distro-status': {
    columnGap: theme.spacing(1),
    '.check-status-button': {
      '.busy-spinner': {
        position: 'absolute',
        top: 10.5,
        left: 0
      },
      '&.loading': {'.MuiSvgIcon-root': {visibility: 'hidden'}}
    }
  },
  '.view-store-link': {
    display: 'flex',
    'svg': {marginRight: theme.spacing(1)}
  },
  '.validated-success': {
    color: theme.colors.palette.green,
    '&.chip': {background: theme.colors.palette.green}
  },
  '.validated-failed': {
    color: theme.colors.palette.red,
    '&.chip': {background: theme.colors.palette.red}
  },
  '.validated-pending': {
    color: theme.colors.text.mediumDecorator,
    '&.chip': {background: theme.colors.text.mediumDecorator}
  },
}));

export function SalesStoreHostingTab({salesStore, updateField}) {
  const [enableHosting, setEnableHosting] = useState(!!salesStore.hosting?.domain);
  const [domain, setDomain] = useState(salesStore.hosting?.domain);
  const [path, setPath] = useState(salesStore.hosting?.path);
  const [title, setTitle] = useState(salesStore.hosting?.title);
  const [favicon, setFavicon] = useState(salesStore.hosting?.favicon);
  const [isSaving, setIsSaving] = useState(false);
  const {hostingStatus: checkStatusApi, isInProgress: isCheckingStatus} = useFetchSalesDocHostingStatus(salesStore._id);
  const [hostingStatus, setHostingStatus] = useState();

  const uploadCache = useS3UploadCache();

  useEffect(() => {
    if (uploadCache.files.length > 0) {
      setFavicon({
        name: uploadCache.files[0].name,
        url: uploadCache.files[0].url
      });
    }
  }, [uploadCache]);

  const handleDeleteFavicon = useCallback(() => {
    setFavicon(null);
    if (uploadCache.files.length > 0) {
      uploadCache.clearCache();
    }
  }, [uploadCache]);

  const handleCheckStatus = useCallback(async () => {
    const res = await checkStatusApi();
    if (res) {
      setHostingStatus(res.hosting);
      return res.hosting;
    }
    return null;
  }, [checkStatusApi]);

  const handleCheckStatusUntil = useCallback(async () => {
    const res = await handleCheckStatus();
    const distro = res?.distros?.find(({domains}) => domains.includes(domain));
    if (!distro?.dnsEntries.some((dnsEntry) => dnsEntry.domain === domain) && distro.status !== 'ERROR') {
      setTimeout(handleCheckStatusUntil, 5000);
    }
  }, [domain, handleCheckStatus]);

  useMountEffect(() => {
    handleCheckStatus().catch();
  });

  const handleEnableHosting = useCallback(async (e) => {
    setEnableHosting(valueFromEvent(e));
    if (!valueFromEvent(e)) {
      await updateField({
        id: salesStore._id,
        props: {hosting: null}
      });
    }
  }, [salesStore._id, updateField]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    await updateField({
      id: salesStore._id,
      props: enableHosting
        ? {'hosting.domain': domain, 'hosting.path': path || null, 'hosting.title': title || null, 'hosting.favicon': favicon || null}
        : {hosting: null}
    });
    handleCheckStatusUntil();
    setIsSaving(false);
  }, [domain, enableHosting, favicon, handleCheckStatusUntil, path, salesStore._id, title, updateField]);

  // There are changes to save if they have turned off hosting, or if they have changed the domain or path and the domain is valid
  const canSave = (!enableHosting && salesStore.hosting?.domain)
    || (enableHosting && isValidDomainName(domain) && isChanged(domain, salesStore.hosting?.domain))
    || (enableHosting && isValidDomainName(domain) && isValidPath(path) && isChanged(path, salesStore.hosting?.path))
    || (enableHosting && isValidDomainName(domain) && isChanged(title, salesStore.hosting?.title))
    || (enableHosting && isValidDomainName(domain) && isChanged(favicon, salesStore.hosting?.favicon));

  const distro = hostingStatus?.distros?.find(({domains}) => domains.includes(domain));
  const domainValidations = distro?.dnsEntries.filter((entry) => entry.domain === domain);
  const domainReady = domainValidations?.every(({status}) => status === 'SUCCESS');
  const otherValidations = distro?.dnsEntries.filter((entry) => entry.domain !== domain && entry.domain !== entry.name);

  return (
    <Column className={'sales-store-hosting-tab'} fillWidth gap>
      <Row paper className={'hosting-details'} pad gap>
        <Column fillWidth gap>
          <Row>
            <HeadingText>
              Enable Hosting
              <Switch checked={enableHosting} onChange={handleEnableHosting} />
            </HeadingText>
          </Row>
          {enableHosting &&
            <>
              <Grid gridTemplateColumns={'auto auto'}>

                <Column className={'hosting-details-property'}>
                  <HeadingText text={'Page Title'} />
                  <TextInput value={title} onChange={(e) => setTitle(valueFromEvent(e))} disabled={!enableHosting} />
                  <BodyText descriptive>Enter the desired Page Title. The page title is shown at the top of a browser window. Max 60 characters. Eg. Wildcats Supporters Club Merch Store</BodyText>
                </Column>

                <Column className={'hosting-details-property'}>
                  <HeadingText text={'Favicon'} />

                  {!favicon &&
                    <Row gap alignCenter className={'favicon-uploader'}>
                      <ImageAndFileUploadManager
                        dropZoneFirst={false}
                        files={[]}
                        value={''}
                        maxSize={MaxFileSize.Max60KB}
                        uploadCache={uploadCache}
                      />
                      <BodyText>Click to Upload</BodyText>
                    </Row>
                  }

                  {favicon &&
                    <Row gap alignCenter className={'favicon-file'}>
                      <ImageThumbnail
                        imageUrl={favicon.url}
                        hoverZoom
                        size={16}
                      />
                      <BodyText text={favicon.name} />
                      <Button prefix={DeleteIcon} onClick={() => handleDeleteFavicon()} />
                    </Row>
                  }

                  <BodyText descriptive>A favicon is a small 16×16 pixel icon that serves as branding for your website. File types: .png; .svg; .ico Download a favicon using <Link href={'https://onlineminitools.com/website-favicon-downloader'}>this tool. </Link></BodyText>
                </Column>

                <Column className={'hosting-details-property'}>
                  <HeadingText text={'Domain Name'} />
                  <TextInput prefix={<BodyText text={'https://'} />} value={domain} onChange={(e) => setDomain(valueFromEvent(e))} disabled={!enableHosting} />
                  <BodyText descriptive>Enter the domain for this store. This same domain can be used for multiple stores. <br />eg. store.mydomain.com</BodyText>
                </Column>

                <Column className={'hosting-details-property'}>
                  <HeadingText text={'Path'} />
                  <TextInput prefix={<BodyText text={'/'} />} value={path} onChange={(e) => setPath(valueFromEvent(e))} disabled={!enableHosting} />
                  <BodyText descriptive>Enter the URL path for this store. The URL path must be unique for this store. <br />eg. wildcats-supporters-club</BodyText>
                </Column>

              </Grid>

              <Row gap>
                <BodyText descriptive text={'Your SalesStore will be hosted at: '} />
                <BodyText text={`https://${domain ? domain : ''}${path ? '/' + path : ''}`} />
              </Row>

              <Button text={isChanged(domain, salesStore.hosting?.domain) ? 'GENERATE DNS RECORDS' : 'SAVE CHANGES'} navMain onClick={handleSave} disabled={!canSave} loading={isSaving} noWrap />
            </>
          }
        </Column>
        <Column gap alignRight>
          <HeadingText text={'Status'} fit />
          {distro && enableHosting &&
            <>
              <Row className={'distro-status'} alignCenter gap>
                <Button navMinor prefix={SyncIcon} onClick={handleCheckStatus} disabled={!enableHosting} loading={isCheckingStatus} className={['check-status-button', isCheckingStatus && 'loading']} noWrap />
                {distro.status === 'READY' && domainReady &&
                  <Chip className={'validated-success'} prefix={ValidatedIcon} text={'SUCCESS'} />
                }
                {distro.status === 'READY' && !domainReady &&
                  <Chip className={'validated-pending'} prefix={ValidationPendingIcon} text={'IN PROGRESS'} />
                }
                {distro.status === 'ERROR' &&
                  <Chip className={'validated-failed'} prefix={ValidationFailedIcon} text={'ERROR'} />
                }
                {distro.status !== 'READY' && distro.status !== 'ERROR' &&
                  <Chip className={'validated-pending'} prefix={ValidationPendingIcon} text={'IN PROGRESS'} />
                }
              </Row>
              {distro.status === 'READY' && domainReady &&
                <Link href={`https://${domain}/${path ?? ''}`} underline className={'view-store-link'}><Decorator decorator={OpenInNewIcon} />View Store</Link>
              }
            </>
          }
          {!distro && enableHosting &&
            <Chip className={'validated-pending'} prefix={ValidationPendingIcon} text={'IN PROGRESS'} />
          }
        </Column>
      </Row>
      {domainValidations?.length > 0 &&
        <Column paper fillWidth pad gap className={'dns-records'}>
          {domainValidations?.length > 0 &&
            <>
              <HeadingText text={'DNS Records'} />

              <HeadingText text={'This Store'} />
              <BodyText descriptive>To connect your domain, you will need to update your DNS records to include the below CNAME records.</BodyText>
              <Table labels={['Status', 'Domain Name', 'Record Type', 'Record Name', 'Record Value']}>
                {[...domainValidations].map((validation) => (
                  <TableRow key={validation.domain}>
                    <TableCell>
                      {validation.status === 'SUCCESS' &&
                        <Decorator className={'validated-success'} decorator={ValidatedIcon} />
                      }
                      {validation.status === 'FAILED' &&
                        <Decorator className={'validated-failed'} decorator={ValidationFailedIcon} />
                      }
                      {validation.status !== 'SUCCESS' && validation.status !== 'FAILED' &&
                        <Decorator className={'validated-pending'} decorator={ValidationPendingIcon} />
                      }
                    </TableCell>
                    <TableCell>{validation.domain}</TableCell>
                    <TableCell>CNAME</TableCell>
                    <TableCell className={'record-name'}>
                      {validation.name}
                      <Button actionPrimary prefix={CopyIcon} tabIndex={-1} onClick={() => copy(validation.name)} />
                    </TableCell>
                    <TableCell>
                      {validation.value}
                      <Button className={'record-value'} actionPrimary prefix={CopyIcon} tabIndex={-1} onClick={() => copy(validation.value)} />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </>
          }
          {(domainValidations?.length > 0 && otherValidations?.length > 0) &&
            <Row divider />
          }
          {otherValidations?.length > 0 &&
            <>
              <HeadingText text={'Other stores using DNS records'} />
              <BodyText descriptive>This store will be hosted on a Hoops server, here are other stores hosted on the same server. If there are errors within this list, this can cause the hosting of this store to be affected.</BodyText>
              <Table labels={['Status', 'Domain Name', 'Record Type', 'Record Name', 'Record Value']}>
                {[...otherValidations].map((validation) => (
                  <TableRow key={validation.domain}>
                    <TableCell>
                      {validation.status === 'SUCCESS' &&
                        <Decorator className={'validated-success'} decorator={ValidatedIcon} />
                      }
                      {validation.status === 'FAILED' &&
                        <Decorator className={'validated-failed'} decorator={ValidationFailedIcon} />
                      }
                      {validation.status !== 'SUCCESS' && validation.status !== 'FAILED' &&
                        <Decorator className={'validated-pending'} decorator={ValidationPendingIcon} />
                      }
                    </TableCell>
                    <TableCell>{validation.domain}</TableCell>
                    <TableCell>CNAME</TableCell>
                    <TableCell className={'record-name'}>{validation.name}</TableCell>
                    <TableCell className={'record-value'}>{validation.value}</TableCell>
                  </TableRow>
                ))}
              </Table>
            </>
          }
        </Column>
      }

      {enableHosting &&
        <Column paper fillWidth pad gap>
          <HeadingText text={'Need Help?'} />
          <BodyText descriptive>Watch the video below to learn how to configure your SalesStore hosting and add the DNS records into the correct place.</BodyText>
          <Youtube opts={{width: 600, height: 480}} videoId='Gi9jb6ia_xg' />
        </Column>
      }
    </Column>
  );
}

function isChanged(value1, value2) {
  return ((value1 || null) !== (value2 || null)) || !!value1 !== !!value2;
}

function isValidDomainName(domainName) {
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.?)+(?!-)([a-zA-Z0-9]{2,63})$/;
  return domainRegex.test(domainName);
}

function isValidPath(path) {
  const pathRegex = /^[a-zA-Z0-9-_/]*$/;
  return pathRegex.test(path);
}

